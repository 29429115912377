import React from 'react';
import PropTypes from 'prop-types';
import style from './style.css';
const hostName = window.location.hostname;
const Appdefs = window.cccisd.appDefs;
const Footer = props => {
    const grantAttribution = props.grantAttribution || Appdefs?.app?.footer?.grantAttribution;
    return (
        <div className={style.footer}>
            <div className={props.className}>
                <div className={style.wrapper}>
                    <div>
                        <p style={grantAttribution ? { marginBottom: 4 } : { marginBottom: 0 }}>
                            Software developed by{' '}
                            <a href={`//www.3cisd.com?r=${hostName}`} rel="noopener noreferrer" target="_blank">
                                3C Institute.
                            </a>{' '}
                            &copy; {new Date().getFullYear()} University of Central Florida – RESTORES, All Rights
                            Reserved
                        </p>
                        {grantAttribution && <small>{grantAttribution}</small>}
                    </div>
                    {props.showPolicyLinks && (
                        <div>
                            {props.showContactUsLink && (
                                <a
                                    href={`//www.3cisd.com/?r=${hostName}/#ccc-lets-talk`}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    Contact Us
                                </a>
                            )}
                            {props.showTermsLink && (
                                <a
                                    href={`//www.3cisd.com/terms-of-use/?r=${hostName}`}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    Terms of Use
                                </a>
                            )}
                            {props.showPolicyLink && (
                                <a
                                    href={`//www.3cisd.com/privacy-policy/?r=${hostName}`}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    Privacy Policy
                                </a>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
Footer.propTypes = {
    className: PropTypes.string,
    showPolicyLinks: PropTypes.bool,
    copyrightAttribution: PropTypes.string,
    copyrightYear: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    grantAttribution: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    showContactUsLink: PropTypes.bool,
    showTermsLink: PropTypes.bool,
    showPolicyLink: PropTypes.bool,
};
Footer.defaultProps = {
    className: 'container',
    showPolicyLinks: true,
    showContactUsLink: true,
    showTermsLink: true,
    showPolicyLink: true,
};
export default Footer;
