import React, { useEffect, useState } from 'react';
import { Progress } from 'cccisd-laravel-assignment';
import Loader from 'cccisd-loader';
import { client } from 'cccisd-apollo';

import progressQuery from './progress.graphql';

const CourseProgress = () => {
    const [data, setData] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    async function getCourseProgress() {
        const response = await client.query({
            query: progressQuery,
            fetchPolicy: 'network-only',
        });
        setData(response.data.flows.deployment);
        setIsLoading(false);
    }

    useEffect(() => {
        getCourseProgress();
    }, []);

    if (isLoading) {
        return <Loader loading={isLoading} />;
    }

    return (
        !isLoading && (
            <>
                <h1>Progress</h1>
                <Progress
                    assignmentId={data.assignment.assignmentId}
                    deploymentId={data.deploymentId}
                    projectId={data.assignment.groupId}
                    labelForSurvey="Module"
                    labelForQuest="Course"
                    hideSystemId
                    hideDeploymentDropdown
                    hideProjectDropdown
                    hideQuestDropdown
                />
            </>
        )
    );
};

export default CourseProgress;
